@import "src/assets/styles/common"

.grid
  background: $color-white
  padding: 24px
  border-radius: 16px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  gap: 14px
  width: 100%
  box-sizing: border-box

  &__top
    font-size: 14px
    font-weight: 400
    color: $color-text-tertiary
    line-height: 130%

    strong
      font-weight: 600
      color: $color-text-secondary

  &__table
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start