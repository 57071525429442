@import "src/assets/styles/common"

.locality-targeting
  border-radius: 12px
  border: 1px solid #e2e8ec
  display: flex
  flex-direction: column
  gap: 14px
  padding: 20px
  width: 100%
  max-height: 550px
  overflow-y: auto
  box-sizing: border-box

  &.is-search
    .locality-targeting-item__children
      margin-left: 0

  &__label
    font-weight: 500

  &__items
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start