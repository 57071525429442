@import "src/assets/styles/common"

.finances-redistribution-modal
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  border: 1px solid $color-input-border
  background-color: $color-white
  width: 430px
  padding: 32px
  gap: 28.5px
  border-radius: 20px

  &__header
    width: 100%
    box-sizing: border-box

  &__body
    display: flex
    flex-direction: column
    gap: 22px
    width: 100%
    box-sizing: border-box

  &__title
    font-size: 26px
    font-weight: 600