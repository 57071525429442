@import "src/assets/styles/common"

.profile-sidebar
  display: flex
  flex-direction: column
  gap: 32px
  padding: 32px
  border-radius: 16px
  background-color: $color-white
  max-width: 400px
  min-width: 400px
  position: absolute
  top: 20px
  height: calc(100% - 2 * var(--modal-backdrop-padding))
  box-sizing: border-box

  &__header
    display: flex
    flex-direction: column
    gap: 24px
    box-sizing: border-box

  &__title
    font-size: 24px
    font-weight: 600

  &__name
    font-size: 20px
    font-weight: 600

  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start


  &__actions
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 10px
    width: 100%
    box-sizing: border-box
    margin-top: auto

    Button
      width: 100%