@import "src/assets/styles/common"

.locality-targeting-item
  width: 100%
  box-sizing: border-box

  &.is-expanded
    & > .locality-targeting-item__header
      .locality-targeting-item
        &__toggle
          transform: rotate(180deg)

    & > .locality-targeting-item__children
      display: flex

  &__header
    display: flex
    gap: 8px
    justify-content: flex-start
    align-items: center
    padding: 12px 0

  &__toggle
    display: flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    margin-left: auto
    cursor: pointer
    border: none
    background: transparent
    padding: 0

  &__children
    display: none
    flex-direction: column

    &.level-1
      display: none
      margin-left: 10px

    &.level-2
      display: none
      margin-left: 20px

    &.level-3
      display: none
      margin-left: 30px

    &.level-4
      display: none
      margin-left: 40px