@import "src/assets/styles/common"

.access-page
  &__content
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 20px
    width: 100%
    height: 100%
    box-sizing: border-box

  &__managers
    padding: 12px 13px
    background-color: $color-white
    border-radius: 14px
    border: 1px solid $color-input-border
    height: 100%
    box-sizing: border-box

  &__clients
    display: flex
    align-items: center
    justify-content: center
    padding: 12px 13px
    background-color: $color-white
    border-radius: 14px
    border: 1px solid $color-input-border
    height: 100%
    width: 100%
    box-sizing: border-box

  &__empty
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 6px
    color: $color-text-tertiary