@import "src/assets/styles/common"

.clients-create-modal
  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 30px
    background: $color-white
    border-radius: 20px
    padding: 32px
    width: 494px

  &__header
    width: 100%

  &__title
    font-size: 24px
    font-weight: 600
    color: $color-text-primary

  &__types
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 18px

  &__accent
    color: $color-text-tertiary

  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    width: 100%
    gap: 22px

  &__inputs
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 22px
    width: 100%

  &__footer
    width: 100%

  &__actions
    width: 100%
    display: flex
    align-items: center
    justify-content: center

    Button
      width: 100%