@import "src/assets/styles/common"

.statistics-list
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 18px
  height: 100%
  width: 100%
  box-sizing: border-box

@media (max-width: 425px)
  .statistics-list
    display: none