.clients-list-empty
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 12px
  height: 100%

  &__text
    margin: 0

  &__button
    font-weight: 500
    padding: 10px 16px