@import "src/assets/styles/common"

.campaign-view-details
  display: flex
  align-items: flex-start
  justify-content: flex-start
  padding: 24px
  border-radius: 16px
  background-color: $color-white

  &__brief
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 12px
    width: 100%

  &__row
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 20px
    width: 100%

  &__name
    width: 200px
    max-width: 200px
    color: $color-text-secondary

  &__value
    width: 50%

  &__video
    width: 544px
    min-width: 544px
    height: 308px
    min-height: 308px
    border-radius: 8px
    overflow: hidden
    margin-left: auto

    video
      width: 100%
      height: 100%
      object-fit: cover

@media (max-width: 425px)
  .campaign-view-details
    flex-direction: column
    gap: 40px

    &__video
      width: 100%
      min-width: 100%
      height: 100%
      min-height: unset