@import "src/assets/styles/common"

.dropdown
  display: flex
  flex-direction: column
  width: 100%
  box-sizing: border-box

  &.has-search
    .dropdown
      &__list
        margin-top: -4px

  &.has-error
    .dropdown
      &__input
        border: 1px solid $color-error

  &.is-opened
    .dropdown
      &__toggle
        transform: rotate(180deg)

      &__input
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0

  &__label
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 14px
    font-weight: 500
    margin-bottom: 8px

    &.is-required
      div:not(.hint)
        &::after
          content: "*"
          color: $color-alert
          position: absolute

  &__search
    margin-top: -1px

    .text-input__input
      border-radius: unset

  &__input
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 8px
    cursor: pointer
    border-radius: 12px
    border: 1px solid $color-input-border
    padding: 6px 12px
    line-height: 1.5

  &__placeholder
    display: flex
    align-items: center
    justify-content: flex-start
    color: $color-text-tertiary
    width: 100%
    box-sizing: border-box

  &__tags
    display: flex
    justify-content: flex-start
    align-items: center
    flex-grow: 1
    flex-wrap: wrap
    gap: 8px

  &__list
    display: flex
    flex-direction: column
    gap: 8px
    max-height: 220px
    overflow-y: auto
    border: 1px solid $color-input-border
    border-top: none
    border-radius: 0 0 8px 8px
    padding: 14px
    position: relative

    &.is-list-scrolled
      padding-bottom: 0

  &__items
    display: flex
    flex-direction: column
    gap: 8px

  &__item
    &-last
      position: sticky
      bottom: -14px
      width: 100%
      display: flex
      align-items: center
      justify-content: flex-start
      background: $color-white
      padding: 8px 0

      button
        background: transparent
        border: none
        margin-left: auto
        color: $color-blue
        font-size: 16px
        font-weight: 600
        cursor: pointer

  &__toggle
    cursor: pointer
    border: none
    background-color: transparent
    margin-left: auto
    width: 20px
    min-width: 20px
    height: 20px
    min-height: 20px
    padding: 0
    transition: .3s

  &__error
    font-size: 13px
    line-height: 14px
    color: $color-error