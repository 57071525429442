@import "src/assets/styles/common"

.campaign-advertiser-mark-modal
  display: flex

  &__content
    background-color: $color-white
    border-radius: 8px
    max-width: calc(604px - 64px)
    padding: 32px

  &__header
    border-bottom: none
    padding: 0
    margin-bottom: 22px

  &__title
    font-size: 24px
    font-weight: 600
    line-height: 130%

  &__body
    padding: 0
    position: relative

    img
      width: 100%