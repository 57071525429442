@import "src/assets/styles/common"

.chips-item
  display: flex
  justify-content: center
  align-items: center
  padding: 8px 12px
  background-color: $color-secondary-accents
  border-radius: 25px
  color: $color-text-secondary
  cursor: pointer
  font-weight: 500
  &:hover,
  &.is-selected
    background-color: $color-blue
    color: $color-white
