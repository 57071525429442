.campaigns-filters
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 24px

  .filter
    &-name
      width: 348px

    &-button
      align-self: end