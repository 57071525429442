@import "src/assets/styles/common"

.clients-plates
  display: none
  padding: 24px
  background-color: $color-white
  border-radius: 16px
  box-sizing: border-box

  &__items
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 14px
    box-sizing: border-box

@media (max-width: 425px)
  .clients-plates
    display: flex
    flex-direction: column
    gap: 14px