@import "vars"

:root
  --modal-backdrop-padding: 20px

html, body
  font-family: $font-default
  font-size: 16px
  line-height: 120%
  color: $color-text-primary

button
  font-family: $font-default