@import "src/assets/styles/common"

.profile-form
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 24px
  border-radius: 16px
  background-color: $color-white
  padding: 32px
  width: 100%
  box-sizing: border-box

  &__wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 20px
    width: 100%
    box-sizing: border-box

  &__actions
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    gap: 16px
    width: 100%
    box-sizing: border-box

  .form
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    width: 100%

    .form
      &__row
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-start
        margin-bottom: 24px
        gap: 34px
        width: 100%

      &__caption
        color: $color-text-tertiary
        font-size: 18px
        font-weight: 600
        line-height: 130%
        margin-bottom: 13px

@media (max-width: 425px)
  .profile-form
    padding: 16px

    &__actions
      padding-left: 16px
      padding-right: 16px
      justify-content: center

      .button
        width: 100%

    .form
      .form
        &__row
          flex-wrap: wrap
          gap: 16px