@import "src/assets/styles/common"

.campaigns-list
  display: flex
  flex-direction: row
  justify-content: flex-start
  flex-wrap: wrap
  gap: 14px
  padding: 24px
  border-radius: 16px
  background-color: $color-white
  width: 100%
  box-sizing: border-box

  &.is-empty
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: transparent
    height: 100%

@media (max-width: 425px)
  .campaigns-list
    padding: 16px