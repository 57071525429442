@import "../../../../../../assets/styles/common"

.campaign-view-statistics
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 14px
  padding: 24px
  border-radius: 16px
  background-color: $color-white

  &__title
    font-size: 24px
    font-weight: 700
