@import "src/assets/styles/common"

.client-plate
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 14px
  padding: 12px
  border: 1px solid $color-input-border
  border-radius: 11px
  box-sizing: border-box
  width: 100%

  &__top
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    box-sizing: border-box

  &__title
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 6px
    max-width: calc(100% - 42px - 12px)
    overflow: hidden
    text-overflow: ellipsis

  &__actions
    display: flex
    align-items: center
    justify-content: center
    padding: 8px
    background: transparent
    border: 1px solid $color-input-border
    border-radius: 8px
    margin-left: auto

  &__label
    font-size: 14px
    font-weight: 500
    color: $color-text-tertiary

  &__button
    cursor: pointer
    border: none
    background-color: transparent
    color: $color-blue
    font-size: 16px
    font-weight: 500
    line-height: 130%
    text-decoration-line: underline
    text-decoration-style: solid
    text-underline-position: from-font
    text-decoration-skip-ink: none
    padding: 0
    text-align: left
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

    &:hover
      text-decoration: none

  &__statistics
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-wrap: wrap
    gap: 14px 40px

  &__stat
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 6px
    width: calc(50% - 20px)

    .client-plate
      &__label
        font-weight: 400
        color: $color-text-secondary

      &__value
        font-weight: 500
        color: $color-text-primary