.campaign-create-form
  &__text
    font-size: 14px
    font-weight: 500
    margin: 0

  &__budget
    display: flex
    flex-direction: column
    gap: 12px

  &__limit
    display: flex
    flex-direction: column
    padding: 16px 20px
    border: 2px solid #e2e8ec
    border-radius: 20px
    position: relative

    &.limit
      display: flex
      flex-direction: column
      gap: 9px
      position: relative
      cursor: pointer

      &.is-selected
        border-color: #0c6dc7
        box-shadow: 0 8px 24px 0 #0c6dc71a

    .limit
      &__label
        display: flex
        justify-content: flex-start
        align-items: center
        gap: 8px
        font-size: 18px
        font-weight: 600
        line-height: 21.78px

      &__badge
        position: absolute
        padding: 4px 6px
        border-radius: 8px
        background-color: #2ac64e
        top: 12px
        right: 10px
        color: #fff
        font-size: 0.875rem

      &__hint
        font-size: 0.875rem
        font-weight: 400
        line-height: 18.2px

  &__calculator
    &.calculator
      display: flex
      flex-direction: row
      flex-wrap: wrap
      gap: 90px

    .calculator
      &__column
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        gap: 20px
        max-width: 360px

      &__total
        .total
          &__label
            color: #8d99a4

          &__amount
            font-size: 1.75rem
            font-weight: 700
            line-height: 33.89px

  &__hint
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 6px
    margin-top: 6px
    font-size: 14px
    font-weight: 400
    line-height: 16.94px
    color: #8d99a4