@import "src/assets/styles/common"

.toast
  display: flex
  align-items: center
  justify-content: center
  gap: 22px
  font-size: 20px
  font-weight: 400
  line-height: 130%
  max-width: 500px
  position: absolute
  bottom: 20px
  right: 20px
  background: $color-white
  box-shadow: 0 32px 64px 0 #00000012
  border-radius: 10px
  padding: 26px
  cursor: pointer
  z-index: 999

  &.success
    border-left: 6px solid $color-success

  &.error
    border-left: 6px solid $color-alert