@import "src/assets/styles/common"

.search-input
  &__wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 8px
    width: 100%

    &.has-error
      .search-input
        &__input
          margin-bottom: 4px
          border: 1px solid $color-error

    &.has-results
      .search-input
        &__input
          border-bottom-left-radius: 0
          border-bottom-right-radius: 0

  &__label
    font-weight: 500
    position: relative

    &.is-required
      &::after
        content: "*"
        color: $color-alert
        position: absolute

  &__search
    display: block
    width: 100%
    position: relative

  &__input
    font-family: $font-default
    color: $color-text-primary
    border: 1px solid $color-input-border
    border-radius: 8px
    padding: 10.5px 14px
    font-size: 16px
    width: calc(100% - 28px)

    &:focus-visible
      outline: none

    &::placeholder
      font-family: $font-default
      color: $color-text-tertiary

    &:read-only
      color: $color-text-primary
      background-color: $color-field-background

  &__results
    position: absolute
    top: 42px
    left: 0
    width: 100%
    background: $color-white
    border: 1px solid $color-input-border
    border-radius: 0 0 8px 8px
    z-index: 1
    overflow: hidden

  &__result
    font-size: 16px
    padding: 10.5px 14px
    width: 100%
    text-overflow: ellipsis
    cursor: pointer

    &:hover
      background: $color-field-background

  &__error
    font-size: 13px
    line-height: 14px
    color: $color-error