@import "src/assets/styles/common"

.clients-transfer-modal
  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 30px
    background: $color-white
    border-radius: 20px
    padding: 32px
    max-width: calc(502px - 64px)

  &__header
    width: 100%

  &__title
    font-size: 24px
    font-weight: 600
    color: $color-text-primary

  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    width: 100%
    gap: 8px

  &__hint
    color: $color-text-tertiary

  &__footer
    width: 100%

  &__actions
    width: 100%
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 16px