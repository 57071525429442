@import "src/assets/styles/common"

.modal
  position: fixed
  width: 100%
  height: 100%
  box-sizing: border-box

  &__backdrop
    position: absolute
    width: 100%
    height: 100%
    background: $color-modal-backdrop
    padding: var(--modal-backdrop-padding)
    box-sizing: border-box

  &__content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    box-sizing: border-box

    &-left
      align-items: flex-start

    &-right
      align-items: flex-end

  &__body
    position: relative
    width: 100%
    height: 100%

  &__close
    background: transparent
    border: none
    position: absolute
    top: 32px
    right: 32px