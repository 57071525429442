.hint
  display: flex
  position: relative

  &__trigger
    border: none
    background-color: transparent
    cursor: pointer

  &__message
    background-color: #eff2f7
    position: absolute
    top: -32px
    left: 10px
    font-size: 14px
    font-weight: 500
    white-space: nowrap
    padding: 6px 10px
    border-radius: 10px
    box-shadow: 0 4px 9.7px 0 #00000012