.targeting-devices
  display: flex
  flex-direction: column
  width: 100%
  box-sizing: border-box

  &__label
    font-weight: 500
    margin-bottom: 8px

  &__items
    display: flex
    gap: 15px

  &__item
    display: flex
    flex-direction: column
    gap: 10px
    border: 1px solid #e2e8ec
    border-radius: 12px
    background: transparent
    padding: 10px
    min-width: 50%
    cursor: pointer

    &.active
      border: 1px solid #0d81ec
      box-shadow: 0 8px 24px 0 #0c6dc71a

  &__icon
    min-width: 62px
    min-height: 62px

  &__description
    display: flex
    flex-direction: column
    gap: 5px

  &__title
    display: block
    font-size: 1rem
    font-weight: 600
    line-height: 130%
    text-align: left

  &__text
    display: block
    font-size: 0.8125rem
    font-weight: 400
    line-height: 130%
    text-align: left
    color: #4e5e6d

@media (max-width: 768px)
  .campaign-format
    &__items
      flex-direction: column
