$color-white: #fff
$color-black: #000
$color-blue: #0d81ec
$color-blue-secondary: #b9dcff
$color-blue-tertiary: #0c6dc7
$color-secondary-accents: #e2effc
$color-info: #e1f8ff
$color-alert: #ef5350
$color-error: #f43f3f
$color-success: #2ac64e

$color-field-background: #eff4f6

$color-input-border: #e2e8ec

$color-foreground: $color-white
$color-background: #eff4f6

$color-text-primary: #18191d
$color-text-secondary: #4e5e6d
$color-text-tertiary: #8d99a4
$color-text-white: $color-white
$color-tab-border: #e0e5e9
$color-modal-backdrop: rgba(0, 0, 0, 0.16)

$font-default: Inter, sans-serif