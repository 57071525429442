@import "src/assets/styles/common"

.campaigns-list-item
  display: flex
  justify-content: flex-start
  gap: 16px
  padding: 12px
  background: #eff4f657
  border: 1px solid #e2e8ec
  border-radius: 12px
  width: calc(100% / 3 - 28px - 48px)
  min-width: calc(494px - 24px - 2px)
  max-width: calc(494px - 24px - 2px)
  box-sizing: border-box

  &__right
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 12px
    overflow: hidden

  &__image
    width: 218px
    height: 123px
    border-radius: 6px
    overflow: hidden
    box-sizing: border-box

    video
      object-fit: cover
      width: 100%
      height: 100%

  &__title
    overflow: hidden
    text-overflow: ellipsis
    font-size: 18px
    font-weight: 600
    width: 100%

  &__description
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 8px
    width: 100%
    font-size: 14px
    font-weight: 400
    color: $color-text-tertiary

  &__stat
    strong
      color: $color-text-primary
      font-weight: 500

@media (max-width: 425px)
  .campaigns-list-item
    flex-direction: column
    min-width: 100%
    max-width: 100%

    &__title
      font-weight: 700

    &__image
      width: 100%
      height: 100%
      border-radius: 10px