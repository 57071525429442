.dropdown-item
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 10px
  cursor: pointer

  &__label
    font-size: 1rem
    font-weight: 400
    color: #4e5e6d