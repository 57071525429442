@import "src/assets/styles/common"

.campaign-create
  &__title
    font-size: 24px
    font-weight: 700

  &__tabs
    display: flex
    justify-content: space-between
    align-items: center