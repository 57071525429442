@import "src/assets/styles/common"

.profile-sidebar-item
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  width: 100%
  margin-bottom: 8px
  border-bottom: 1px solid #E2E8EC

  &:last-of-type
    border-bottom: none

  &__label
    font-size: 14px
    font-weight: 400
    color: #8D99A4A3

  &__value
    font-size: 16px
    font-weight: 500
    color: $color-text-primary
    padding: 8px 0