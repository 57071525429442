@import "src/assets/styles/common"

.header
  background: #fff
  //height: 36px
  margin-bottom: 22px
  border-radius: 0 0 16px 16px
  display: flex
  justify-content: flex-start
  align-items: center
  padding: 12px 48px
  width: 100%
  box-sizing: border-box

  &__burger
    display: none

  &__nav
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    margin-left: auto
    gap: 26px

  &__finances
    display: flex
    flex-direction: row
    align-items: flex-end
    justify-content: flex-start
    gap: 8px
    border: none
    background: transparent
    padding: 0
    cursor: pointer

  &__balance
    font-size: 16px
    font-weight: 600

    span
      color: $color-text-tertiary
      font-weight: 500

  &__profile
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 8px
    border: none
    background-color: transparent
    cursor: pointer
    font-size: 16px
    font-weight: 500

@media (max-width: 425px)
  .header
    padding: 18px 16px

    &__burger
      display: flex
      align-items: center
      justify-content: center
      margin-left: auto
      background: transparent
      border: none
      padding: 0

    &__nav
      display: none