@import "src/assets/styles/common"

.chips
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 8px

  &__label
    font-weight: 500

  &__list
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 12px