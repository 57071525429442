@import "src/assets/styles/common"

.button
  display: flex
  justify-content: center
  align-items: center
  font-family: $font-default
  font-size: 16px
  font-weight: 600
  line-height: 120%
  padding: 12px 24px
  color: $color-white
  background-color: $color-blue
  border: 1px solid $color-blue
  border-radius: 12px
  cursor: pointer
  &:disabled
    opacity: .5
    pointer-events: none

  &:hover
    background: linear-gradient(90deg, #0d81ec 0%, #58c2ff 100%)
    border: 1px solid $color-blue
    color: white

  &-secondary
    color: $color-text-secondary
    background-color: $color-secondary-accents
    border: 1px solid $color-secondary-accents

  &-white
    color: $color-text-primary
    background-color: $color-white
    border: 1px solid $color-input-border