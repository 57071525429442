@import "src/assets/styles/common"

.page-title
  margin-bottom: 25px

  &__wrapper
    display: flex
    justify-content: flex-start
    align-items: center

  &__title
    font-size: 24px
    font-weight: 700
    margin: 0

  &__button
    margin-left: auto

@media (max-width: 425px)
  .page-title
    &__wrapper
      flex-direction: column
      align-items: flex-start
      gap: 12px

    &__button
      font-weight: 500
      margin-left: unset
      width: 100%