@import "src/assets/styles/common"

.file-input
  &__wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 8px
    width: 100%
    position: relative

  &__label
    font-weight: 500

    &.is-required
      &::after
        content: "*"
        color: $color-alert
        position: absolute

  &__hidden
    display: none
    position: absolute

  &__content
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 20px
    border: 1px solid $color-input-border
    border-radius: 8px
    padding: 12px 14px
    width: 100%
    box-sizing: border-box

  &__error
    font-size: 13px
    line-height: 14px
    color: $color-error