@import "src/assets/styles/common"

.finances-redistribution
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  gap: 38px
  border: 1px solid $color-input-border
  border-radius: 10px
  padding: 20px
  max-width: 300px
  box-sizing: border-box

@media (max-width: 425px)
  .finances-redistribution
    width: 100%
    min-width: 100%

    &__text
      font-weight: 600

    &__button
      width: 100%

      button
        width: 100%