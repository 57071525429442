@import "src/assets/styles/common"

.info
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  font-size: 14px
  font-weight: 400
  padding: 10px 16px
  background-color: $color-info
  border: 1px solid #00000014
  border-radius: 12px
  box-sizing: border-box
  width: 100%