.dropdown-tag
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 6px
  font-size: 1rem
  color: #4e5e6d
  background-color: #eff4f6
  border-radius: .5rem
  padding: 6px 8px

  &__label
    display: flex
    justify-content: center
    align-items: center

  &__close
    font-size: 0
    border: none
    background-color: transparent
    background-position: center
    width: 16px
    height: 16px
    padding: 0

    svg
      width: 16px
      height: 16px