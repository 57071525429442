@import "src/assets/styles/common"

.finances-history-cell-download
  display: flex
  justify-content: center
  align-items: center

  &__button
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    border: none
    background-color: $color-secondary-accents
    border-radius: 50%
    width: 32px
    height: 32px