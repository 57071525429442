.checkbox
  &__icon
    display: flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    position: relative
    cursor: pointer
    overflow: hidden
    border: none
    background: transparent
    padding: 0