@import "src/assets/styles/common"

.campaign-create-form
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 24px
  border-radius: 16px
  background-color: $color-white
  padding: 32px
  width: 100%
  box-sizing: border-box

  &__tabs
    display: flex
    justify-content: center
    align-items: center
    gap: 56px
    width: 100%

  &__tab
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    min-width: 228px
    padding-bottom: 8px
    border-bottom: 4px solid $color-tab-border

    &.active
      border-bottom: 4px solid $color-blue

  &__group
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 24px
    width: 100%

  &__title
    font-size: 20px
    font-weight: 700

  &__fields
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 34px
    width: 100%

  &__field
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 12px
    width: 100%

  &__actions
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

    button:last-of-type
      margin-left: auto