@import "src/assets/styles/common"

.finances-add-amount-modal
  background-color: $color-white
  width: 430px
  padding: 32px
  display: flex
  flex-direction: column
  gap: 28.5px
  border-radius: 20px

  &__title
    font-size: 26px
    font-weight: 600

  &__input
    margin-bottom: 30px

  &__button
    font-weight: 500
    width: 100%