.statistics-filters
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 24px

  &__actions
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 12px

  .filter
    &-name
      width: 348px

    &-button
      align-self: end