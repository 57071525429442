@import "src/assets/styles/common"

.switch
  background-color: $color-field-background
  display: flex
  flex-direction: row
  padding: 4px 6px
  border-radius: 8px

  &__button
    background: transparent
    border: none
    color: $color-text-secondary
    font-size: 16px
    font-weight: 500
    padding: 10px 16px
    cursor: pointer

    &.active
      color: $color-text-primary
      background-color: $color-white
      box-shadow: 0 2px 8px 0 #0c6dc724
      border-radius: 8px
      font-weight: 600