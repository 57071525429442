@import "src/assets/styles/common"

.data-range-input
  &__wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 8px

    &.has-error
      .text-input
        &__input
          border: 1px solid $color-error

  &__label
    font-weight: 500

  &__inputs
    display: flex
    flex-direction: row
    gap: 10px

    .is-empty
      input
        color: #8d99a4

    input
      position: relative
      &::-webkit-calendar-picker-indicator
        background: transparent
        bottom: 0
        color: transparent
        cursor: pointer
        height: auto
        left: 0
        position: absolute
        right: 0
        top: 0
        width: auto