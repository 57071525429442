@import "src/assets/styles/common"

.support-modal
  display: flex

  &__content
    background-color: $color-white
    border-radius: 8px
    max-width: calc(604px - 64px)
    padding: 32px

  &__header
    border-bottom: none
    padding: 0
    margin-bottom: 22px

  &__title
    font-size: 24px
    font-weight: 600
    line-height: 130%

  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    gap: 14px
    padding: 0
    position: relative
    color: $color-text-secondary

  &__row
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 6px
    width: 100%

    a
      color: $color-text-primary
      font-weight: 500
      text-decoration: none

      strong
        color: $color-text-primary
        font-weight: 500

  &__copy
    display: flex
    align-items: center
    justify-content: center
    background: transparent
    border: none
    cursor: pointer

  &__chat
    width: 100%