@import "src/assets/styles/common"

.text-input
  &__wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    width: 100%
    box-sizing: border-box

    &.has-error
      .text-input
        &__input
          border: 1px solid $color-error

  &__label
    font-weight: 500
    position: relative
    margin-bottom: 8px

    &.is-required
      &::after
        content: "*"
        color: $color-alert
        position: absolute

  &__input
    font-family: $font-default
    line-height: 19.36px
    color: $color-text-primary
    border: 1px solid $color-input-border
    border-radius: 8px
    padding: 10px 14px
    font-size: 16px
    width: 100%
    margin-bottom: 4px
    max-height: 40px
    box-sizing: border-box

    &:focus-visible
      outline: none

    &::placeholder
      font-family: $font-default
      color: $color-text-tertiary

    &:read-only
      color: $color-text-primary
      background-color: $color-field-background

  &__error
    font-size: 13px
    line-height: 14px
    color: $color-error