@import "src/assets/styles/common"

.pill
  background-color: transparent
  border: none
  display: flex
  align-items: center
  justify-content: flex-start
  color: $color-text-primary
  font-size: 16px
  font-weight: 400
  gap: 8px
  padding: 0
  position: relative
  cursor: pointer

  &.active
    .pill
      &__icon
        background: $color-white
        border: 1px solid $color-blue
        
        &::before
          background: #0d81ec
          border-radius: 50%
          content: ""
          display: block
          height: 10px
          left: 2px
          position: absolute
          top: 2px
          width: 10px

  &__icon
    background: $color-field-background
    border: 1px solid $color-input-border
    border-radius: 50%
    display: block
    height: 14px
    position: relative
    width: 14px
