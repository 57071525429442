@import "src/assets/styles/common"

.pagination
  display: flex
  justify-content: flex-start
  align-items: flex-start
  gap: 4px
  box-sizing: border-box

  &__items
    display: flex
    justify-content: flex-start
    align-items: flex-start
    width: 100%
    background-color: #f9f9f9
    padding: 0 8px
    border-radius: 60px

  &__item
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    text-decoration: none
    font-size: 14px
    line-height: 16.94px
    color: $color-text-secondary

    &:hover,
    &.active
      color: $color-white
      background: $color-blue
      border-radius: 8px

  &__arrow
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    min-width: 32px
    height: 32px
    min-height: 32px
    color: $color-text-tertiary
    background-color: #f9f9f9
    border-radius: 55px
    cursor: pointer

    &-next
      transform: rotate(180deg)

    &:hover
      color: $color-white
      background: $color-blue

      svg
        path
          stroke: $color-white

  &__separator
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px