@import "src/assets/styles/common"

.content-file-input
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  gap: 20px

  &__hint
    button
      font-size: 16px
      line-height: 130%
      background: transparent
      border: none
      cursor: pointer
      padding: 0
      color: $color-blue
      text-decoration: underline

  &__preview
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 20px

  .preview
    &__wrapper
      position: relative
      border-radius: 8px
      width: 315px
      height: 172px
      overflow: hidden

    &__close
      display: flex
      align-items: center
      justify-content: center
      border: none
      width: 24px
      height: 24px
      border-radius: 50%
      position: absolute
      top: 6px
      right: 6px
      z-index: 9
      cursor: pointer

    &__video
      width: 100%
      height: 100%
      object-fit: cover

    &__description
      display: flex
      gap: 0
      flex-grow: 1
      justify-content: center
      overflow: hidden
      font-weight: 500

    &__name
      width: 100%
      font-size: 1rem
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap