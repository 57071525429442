.mobile-menu
  display: none
  width: 100%

@media (max-width: 425px)
  .mobile-menu
    display: none

    &__sidebar
      display: flex
      width: 100%
      border-radius: 0 0 12px 12px