@import "src/assets/styles/common"

@mixin activeMixin
  color: #000000
  font-weight: 600
  background-color: #eff4f6

  .link
    &__icon
      svg
        path
          stroke: #000000


.sidebar
  display: flex
  flex-direction: column
  border-radius: 16px
  width: 304px
  min-width: 304px
  background-color: #fff
  padding: 12px
  height: 100%
  overflow: hidden
  box-sizing: border-box

  &__nav
    display: flex
    flex-direction: column
    height: 100%

  &__item
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start

    &:last-of-type
      margin-top: auto

  &__icon
    display: inline-flex
    width: 24px
    height: 24px

    svg
      width: 100%
      height: 100%
      object-fit: cover

  &__title
    display: inline-flex

  &__divider
    width: 100%
    height: 1px
    background-color: $color-input-border

  &__button
    display: flex
    justify-content: center
    align-items: center
    gap: 6px
    border: none
    border-radius: 8px
    padding: 6px 16px
    width: 100%
    color: $color-text-tertiary
    background-color: $color-secondary-accents
    font-size: 16px
    font-family: $font-default
    font-weight: 500
    margin-bottom: 12px
    cursor: pointer

  &__link
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 12px
    padding: 14.5px 16px
    border-radius: 10px
    width: 100%
    font-family: Inter, sans-serif
    font-size: 16px
    font-weight: 500
    line-height: 120%
    color: $color-text-secondary
    text-decoration: none
    border: none
    background: transparent
    cursor: pointer

    &:hover
      @include activeMixin

    &.active
      @include activeMixin

  &__footer
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 20px

    .sidebar
      &__item
        display: flex
        align-items: center
        justify-content: flex-start
        gap: 12px
        padding: 0 16px

      &__title
        color: $color-text-tertiary
        font-weight: 500

        &:last-of-type
          margin-top: unset

@media (max-width: 425px)
  .sidebar
    display: none