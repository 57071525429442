@import "src/assets/styles/common"

.clients-list-cell-name
  display: flex
  justify-content: flex-start
  align-items: center

  &__button
    cursor: pointer
    border: none
    background-color: transparent
    color: $color-blue
    font-size: 16px
    font-weight: 500
    line-height: 130%
    text-decoration-line: underline
    text-decoration-style: solid
    text-underline-position: from-font
    text-decoration-skip-ink: none
    text-align: left

    &:hover
      text-decoration: none