@import "src/assets/styles/common"

.finances-history
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: 18px
  height: 100%
  width: 100%
  box-sizing: border-box

  &__switch
    background-color: $color-field-background
    display: flex
    flex-direction: row
    padding: 4px 6px
    border-radius: 8px

  &__button
    background: transparent
    border: none
    color: $color-text-secondary
    font-size: 16px
    font-weight: 500
    padding: 10px 16px
    cursor: pointer

    &.active
      color: $color-text-primary
      background-color: $color-white
      box-shadow: 0 2px 8px 0 #0c6dc724
      border-radius: 8px
      font-weight: 600

  &__empty
    height: 100%
    display: flex
    justify-content: center
    align-items: center