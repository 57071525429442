@import "src/assets/styles/common"

.finances-stat
  display: flex
  justify-content: space-between
  align-items: stretch
  gap: 44px
  border: 1px solid $color-blue
  border-radius: 10px
  box-shadow: 0 8px 24px 0 #0c6dc71a
  padding: 20px
  min-width: 418px
  box-sizing: border-box

  &__button
    font-weight: 500
    padding: 8px 12px
    line-height: 20px
    margin-top: auto

  &__column
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    gap: 8px

  &__used
    position: relative
    width: 100%

  &__title
    display: flex
    justify-content: space-between
    align-items: center
    gap: 30px
    font-size: 14px
    font-weight: 500
    margin-bottom: 5px

  &__value
    font-size: 24px
    font-weight: 700

    &-gray
      font-size: 22px
      font-weight: 600
      color: $color-text-tertiary

@media (max-width: 425px)
  .finances-stat
    flex-direction: column
    width: 100%
    min-width: 100%

    &__column
      flex-direction: row