@import "src/assets/styles/common"

.campaign-view-page
  &__top
    .page-top__wrapper
      display: flex
      align-items: center
      justify-content: flex-start
      gap: 18px

  &__back
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    border-radius: 10px
    border: none
    background-color: $color-field-background
    cursor: pointer

  &__actions
    display: flex
    align-items: center
    justify-content: center
    gap: 12px
    margin-left: auto
