@import "src/assets/styles/common"

.finances-add-item
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  gap: 8px
  border: 1px solid #E2E8EC
  border-radius: 1rem
  background-color: $color-white
  padding: 0 12px
  height: 76px
  cursor: pointer
  font-size: 16px