.finances
  &__top
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 13px

  .page-top__wrapper
    display: flex
    justify-content: flex-start
    align-items: stretch
    gap: 14px

@media (max-width: 425px)
  .finances
    &__top
      .page-top__wrapper
        flex-direction: column