@import "src/assets/styles/common"

.table
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  width: 100%
  box-sizing: border-box

  &__header
    border-radius: 8px
    display: grid
    grid-column-gap: 1px
    grid-row-gap: 0
    width: 100%

    .table
      &__cell
        background-color: $color-field-background
        color: $color-text-secondary
        font-size: 14px
        line-height: 130%

        &:first-of-type
          border-top-left-radius: 8px
          border-bottom-left-radius: 8px

        &:last-of-type
          border-top-right-radius: 8px
          border-bottom-right-radius: 8px

  &__body
    display: grid
    grid-column-gap: 1px
    grid-row-gap: 0
    width: 100%

  &__cell
    padding: 12px 8px
    border-bottom: 1px solid $color-input-border