@import "src/assets/styles/common"

.page-top
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start

  &__wrapper
    background: $color-white
    border-radius: 16px
    padding: 24px
    width: 100%
    box-sizing: border-box

  &__client
    font-size: 18px
    font-weight: 500
    color: $color-text-secondary
    margin-bottom: 12px

@media (max-width: 425px)
  .page-top
    &__wrapper
      flex-direction: column
      padding: 20px 16px
      align-items: flex-start