html, body, #root
  display: flex
  padding: 0
  margin: 0
  width: 100%
  min-height: 100%
  box-sizing: border-box

.app
  display: flex
  flex-direction: column
  background: #eff4f6
  width: 100%
  box-sizing: border-box

.main
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: stretch
  gap: 20px
  padding-left: 20px
  padding-right: 20px
  width: 100%
  flex-grow: 0
  height: 100%
  padding-bottom: 20px
  box-sizing: border-box

.content
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: 100%
  box-sizing: border-box

@media (max-width: 425px)
  .main
    padding-left: 0
    padding-right: 0
    flex-direction: column

  .is-mobile-menu-opened
    position: fixed
    height: 100%

    &::after
      content: ""
      display: block
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      background-color: #00000029

    .header
      margin-bottom: 0
      border-radius: 0
      padding-bottom: 0
      z-index: 1

    .mobile-menu
      display: flex
      z-index: 1
      position: absolute
      top: 54px