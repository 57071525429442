@import "src/assets/styles/common"

.grid-table-cell-actions
    display: flex
    justify-content: center
    align-items: center
    position: relative

    &__button
        background: transparent
        border: none
        border-radius: 8px
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        width: 32px
        height: 32px

        &:hover
            background: $color-field-background

    &__items
        display: flex
        flex-direction: column
        align-items: center
        position: absolute
        top: 20.5px
        right: 15px
        border-radius: 6px
        border: 1px solid $color-input-border
        box-shadow: 0 8px 24px 0 #959DA533
        background-color: $color-white
        min-width: max-content
        z-index: 2

    &__item
        display: flex
        justify-content: flex-start
        align-items: center
        padding: 12px
        cursor: pointer
        width: 100%
        box-sizing: border-box
        font-size: 15px
        line-height: 130%

        &:hover
            background-color: $color-field-background